import React, { useEffect, useState } from "react";
import { Modal, Input, Switch, Form } from "antd";
import { AiOutlineLock, AiOutlineInfoCircle } from "react-icons/ai";
import axios from "axios";

const CreateSearchEngine = ({ open, onClose, setSearchEngines, messageApi }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Add cleanup function
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  const handleCreateEngine = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/api/search-engines`, values, { withCredentials: true });

      if (response.data.engine) {
        setSearchEngines((prev) => [
          {
            id: response.data.engine._id,
            name: response.data.engine.name,
            description: response.data.engine.description,
            documentsCount: 0,
            lastUpdated: new Date().toISOString().split("T")[0],
            status: "ready",
            queryCount: 0,
            isPrivate: response.data.engine.isPrivate,
          },
          ...prev,
        ]);
        messageApi.success("Search engine created successfully");
        onClose();
      }
    } catch (error) {
      console.error("Error creating search engine:", error);
      messageApi.error(error.response?.data?.error || "Failed to create search engine");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<div className="text-xl font-semibold">Create New Search Engine</div>}
      open={open}
      onCancel={onClose}
      footer={null} // Remove default footer to prevent conflicts
      width={500}
      maskClosable={!loading}
      closable={!loading}
    >
      <Form
        form={form}
        layout="vertical"
        className="mt-4"
        onFinish={handleCreateEngine} // Form's own submission handler
        disabled={loading}
      >
        <Form.Item
          name="name"
          label="Engine Name"
          rules={[
            { required: true, message: "Please enter an engine name" },
            { max: 100, message: "Name cannot exceed 100 characters" },
            { whitespace: true, message: "Name cannot be empty" },
          ]}
        >
          <Input placeholder="Enter engine name" className="w-full px-3 py-2 border rounded-lg" maxLength={100} />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ max: 500, message: "Description cannot exceed 500 characters" }]}
        >
          <Input.TextArea
            placeholder="Describe what this search engine is for..."
            rows={4}
            className="w-full px-3 py-2 border rounded-lg"
            maxLength={500}
            showCount
          />
        </Form.Item>

        <Form.Item name="isPrivate" valuePropName="checked" initialValue={true} className="mb-2 pt-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <AiOutlineLock className="text-gray-400" />
              <span>Make this engine private</span>
            </div>
            <Switch defaultChecked />
          </div>
        </Form.Item>

        <div className="p-3 mb-4 bg-gray-50 rounded-lg flex items-start space-x-2">
          <AiOutlineInfoCircle className="text-blue-600 mt-1 flex-shrink-0" />
          <p className="text-sm text-gray-600">
            Private engines are only accessible to you and team members you invite. Your engine URL will be based on
            your unique user subdomain.
          </p>
        </div>

        <div className="flex justify-end space-x-2">
          <button
            type="button"
            className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            disabled={loading}
          >
            Create
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateSearchEngine;
