import React, { useState, useEffect, useCallback } from "react";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import SearchEngineCard from "../components/SearchEngineCard";
import { TypeAnimation } from "react-type-animation";
import CreateSearchEngine from "../components/CreateSearchEngine";
import axios from "axios";
import { message } from "antd";

const Dashboard = ({ user }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchEngines, setSearchEngines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchSearchEngines = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/search-engines`, {
        withCredentials: true,
      });

      if (response.data.engines) {
        setSearchEngines(
          response.data.engines.map((engine) => ({
            id: engine._id,
            name: engine.name,
            description: engine.description,
            documentsCount: engine.metrics?.documentCount || 0,
            lastUpdated: new Date(engine.updatedAt).toISOString().split("T")[0],
            status: engine.status,
            queryCount: engine.metrics?.totalQueries || 0,
            isPrivate: engine.isPrivate,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching search engines:", error);
      message.error("Failed to load search engines");
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchSearchEngines();
  }, [fetchSearchEngines]);

  if (!loading)
    return (
      <div style={{ minHeight: "calc(100svh - 3.8rem)" }} className="w-full flex justify-center p-3 bg-gray-100">
        {contextHolder}
        <CreateSearchEngine
          user={user}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setSearchEngines={setSearchEngines}
          messageApi={messageApi}
        />

        <div className="max-w-screen-xl w-full">
          {/* Header Section */}
          <div className="my-4 space-y-1">
            <h1 className="text-2xl font-bold">My Search Engines</h1>
            <p className="text-gray-600">View and manage all your Search Engines in one place.</p>
          </div>
          {searchEngines.length > 0 && (
            <div className="flex justify-between items-center mb-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="flex items-center space-x-1.5 text-sm font-medium bg-blue-600 text-white rounded-full px-4 py-1.5 hover:bg-opacity-80 transition"
              >
                <AiOutlinePlus />
                <p>Create New Engine</p>
              </button>
            </div>
          )}

          <div className="bg-white rounded-lg p-4 shadow">
            {searchEngines.length > 0 ? (
              // Search Engines Grid
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {searchEngines.map((engine) => (
                  <SearchEngineCard key={engine.id} engine={engine} />
                ))}
              </div>
            ) : (
              // Empty State
              <div className="flex flex-col items-center justify-center text-center bg-neutral-50 h-full rounded-xl p-3 py-8">
                <div className="flex items-center gap-2 bg-white p-3 px-4 rounded border border-gray-200 mb-2 font-semibold w-full text-base md:text-xl max-w-96">
                  <AiOutlineSearch className="text-gray-400 flex-shrink-0 text-xl" />
                  <TypeAnimation sequence={["Create your first search engine"]} cursor={false} speed={50} />
                </div>
                <p className="text-gray-600 max-w-md mb-8 mt-4 text-sm md:text-base">
                  Get started by creating a search engine. Upload your documents and start searching through them in
                  seconds.
                </p>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center space-x-2 bg-blue-600 text-white rounded-full px-6 py-2.5 hover:bg-opacity-80 transition"
                >
                  <AiOutlinePlus />
                  <span>Create Search Engine</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

export default Dashboard;
