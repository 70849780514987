import React, { useCallback, useEffect, useState } from "react";
import { Upload, Modal, Progress, message, Button, Input, Table } from "antd";
import {
  AiOutlineUpload,
  AiOutlineFile,
  AiOutlineCheck,
  AiOutlineSync,
  AiOutlineWarning,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineLink,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import axios from "axios";

const DocumentManagement = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { engineId } = useParams();
  const [loading, setLoading] = useState(true);
  const [searchEngine, setSearchEngine] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [indexingProgress] = useState(85);

  const fetchSearchEngine = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/search-engines/${engineId}`, {
        withCredentials: true,
      });

      if (response.data) {
        setSearchEngine({
          ...response.data,
          isEditing: false,
          searchUrl: `${window.location.origin}/search/${engineId}`,
        });
      }
    } catch (error) {
      console.error("Error fetching search engine:", error);
      message.error("Failed to load search engine details");
    } finally {
      setLoading(false);
    }
  }, [engineId, API_URL]);

  // Fetch search engine details
  useEffect(() => {
    fetchSearchEngine();
  }, [engineId, fetchSearchEngine]);

  // Update search engine details
  const handleEngineUpdate = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/api/search-engines/${engineId}`,
        {
          name: searchEngine.name,
          description: searchEngine.description,
        },
        { withCredentials: true }
      );

      if (response.data) {
        setSearchEngine((prev) => ({
          ...prev,
          ...response.data.engine,
          isEditing: false,
        }));
        message.success("Search engine details updated successfully");
      }
    } catch (error) {
      console.error("Error updating search engine:", error);
      message.error("Failed to update search engine");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex items-center space-x-3">
          <AiOutlineFile className="w-4 h-4 text-gray-400 flex-shrink-0" />
          <div className="min-w-0">
            <p className="font-medium truncate">{text}</p>
            <p className="text-xs text-gray-500">
              {record.type} • {record.size}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Upload Date",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (text) => <span className="text-xs text-gray-600">{text}</span>,
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div className={`flex items-center space-x-2 ${getStatusColor(status)}`}>
          {getStatusIcon(status)}
          <span className="text-xs capitalize">{status}</span>
        </div>
      ),
      width: "15%",
    },
    {
      title: "",
      key: "actions",
      width: "5%",
      render: (_, record) => (
        <Button
          type="text"
          icon={<AiOutlineDelete className="w-4 h-4" />}
          onClick={() => handleDelete(record.id)}
          className="text-gray-400 hover:text-red-500"
        />
      ),
    },
  ];

  // Handle file upload
  const handleUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${API_URL}/api/search-engines/${engineId}/documents`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const newDocument = response.data.document;
      setDocuments((prev) => [...prev, newDocument]);
      onSuccess();
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      console.error("Error uploading file:", error);
      onError();
      message.error(`Failed to upload ${file.name}`);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "indexed":
        return "text-green-600";
      case "processing":
        return "text-blue-600";
      case "failed":
        return "text-red-600";
      default:
        return "text-gray-600";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "indexed":
        return <AiOutlineCheck className="w-4 h-4" />;
      case "processing":
        return <AiOutlineSync className="w-4 h-4 animate-spin" />;
      case "failed":
        return <AiOutlineWarning className="w-4 h-4" />;
      default:
        return null;
    }
  };

  // Handle document deletion
  const handleDelete = (docId) => {
    Modal.confirm({
      title: "Delete Document",
      content: "Are you sure you want to delete this document?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/api/search-engines/${engineId}/documents/${docId}`, { withCredentials: true });
          setDocuments((prev) => prev.filter((doc) => doc.id !== docId));
          message.success("Document deleted successfully");
        } catch (error) {
          console.error("Error deleting document:", error);
          message.error("Failed to delete document");
        }
      },
    });
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4">Loading search engine...</p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "calc(100svh - 3.8rem)" }} className="w-full max-w-screen-xl mx-auto p-4 bg-neutral-50">
      {/* Search Engine Details Section */}
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        {searchEngine.isEditing ? (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <Input
                value={searchEngine.name}
                onChange={(e) => setSearchEngine((prev) => ({ ...prev, name: e.target.value }))}
                placeholder="Search Engine Name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
              <Input.TextArea
                value={searchEngine.description}
                onChange={(e) => setSearchEngine((prev) => ({ ...prev, description: e.target.value }))}
                placeholder="Search Engine Description"
                rows={2}
              />
            </div>
            <div className="flex justify-end space-x-3">
              <Button onClick={() => setSearchEngine((prev) => ({ ...prev, isEditing: false }))}>Cancel</Button>
              <Button type="primary" onClick={handleEngineUpdate}>
                Save Changes
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-start mb-3">
              <div>
                <h1 className="text-xl font-bold mb-2">{searchEngine.name}</h1>
                {searchEngine?.description && <p className="text-gray-600 text-sm">{searchEngine.description}</p>}
              </div>
              <Button
                icon={<AiOutlineEdit />}
                onClick={() => setSearchEngine((prev) => ({ ...prev, isEditing: true }))}
              >
                Edit
              </Button>
            </div>
            <button className="w-full flex items-center justify-between bg-gray-50 p-3 rounded-lg text-blue-600 hover:bg-gray-100 transition-colors group">
              <div className="flex items-center space-x-2">
                <AiOutlineLink className="flex-shrink-0" />
                <p className="text-sm">{searchEngine.searchUrl}</p>
              </div>
              <AiOutlineArrowRight className="opacity-0 group-hover:opacity-100 transition-opacity" />
            </button>
          </div>
        )}
      </div>

      <div className="gap-4 flex md:flex-row flex-col">
        <div className="lg:col-span-2 w-full">
          <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-lg font-semibold mb-4">Documents</h2>

            {/* Upload Area */}
            <Upload.Dragger
              customRequest={handleUpload}
              multiple={true}
              showUploadList={false}
              accept=".pdf,.doc,.docx,.txt"
              className="mb-6"
            >
              <p className="text-4xl mb-4">
                <AiOutlineUpload className="mx-auto text-gray-400" />
              </p>
              <p className="font-semibold">Click or drag files to upload</p>
              <p className="text-xs text-gray-500 mt-2">Support for PDF, DOC, DOCX, TXT</p>
            </Upload.Dragger>

            {/* Documents Table */}
            <Table
              columns={columns}
              dataSource={documents}
              rowKey="id"
              pagination={{
                pageSize: 10,
                position: ["bottomCenter"],
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} documents`,
              }}
              className="ant-table-documents [&_.ant-table-thead>tr>th]:py-2 [&_.ant-table-tbody>tr>td]:py-2 mt-4"
            />
          </div>
        </div>

        <div>
          <div className="bg-white rounded-lg shadow p-4 max-w-md">
            <h2 className="text-lg font-semibold mb-4">Indexing Status</h2>
            <div className="space-y-4">
              <Progress percent={indexingProgress} />
              <p className="text-sm text-gray-600">{indexingProgress}% of documents indexed</p>
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                <p className="text-sm text-blue-700">
                  Indexing is in progress. This may take a few minutes depending on the size of your documents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentManagement;
