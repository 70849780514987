import axios from "axios";
import React, { useCallback, useEffect, useState, useRef } from "react"; // Added useRef
import { IoIosSearch } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Switch } from "antd";

const SearchBar = ({ size, passedOnQuery = "" }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [devMode, setDevMode] = useState(false);
  const [stats, setStats] = useState({
    indexedTools: 0,
    submittedTools: 0,
  });

  // Add ref for the input element
  const inputRef = useRef(null);

  // Focus input on component mount
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("q");
    const modeParam = new URLSearchParams(location.search).get("mode");
    if (queryParam) {
      setQuery(queryParam);
      setDevMode(modeParam === "technical" ? true : false);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/analytics`);
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchStatistics();
  }, [API_URL]);

  const handleSearch = useCallback(
    (passedOnQuery) => {
      const mode = devMode ? "technical" : "normal";

      if (passedOnQuery) {
        navigate(`/search?q=${encodeURIComponent(passedOnQuery)}&mode=${mode}`);
        return;
      }
      if (query.trim()) {
        navigate(`/search?q=${encodeURIComponent(query)}&mode=${mode}`);
      }
    },
    [devMode, query, navigate]
  );

  const toggleDevMode = useCallback(
    (checked) => {
      setDevMode(checked);
      const currentParams = new URLSearchParams(location.search);
      const queryParam = currentParams.get("q");

      if (queryParam) {
        currentParams.set("mode", checked ? "technical" : "normal");
        navigate(`/search?${currentParams.toString()}`, { replace: true });
      }
    },
    [location.search, navigate]
  );

  useEffect(() => {
    if (passedOnQuery) {
      handleSearch(passedOnQuery);
    }
    return () => {};
  }, [passedOnQuery, handleSearch]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={`${size === "sm" ? "max-w-screen-sm text-sm" : "max-w-screen-md py-2"} w-full relative z-50`}>
      <div
        className={`${
          size === "sm" ? "max-w-screen-sm py-1 text-sm" : "max-w-screen-md py-2"
        } flex items-center border w-full bg-white border-gray-300 px-3 focus-within:ring-2 focus-within:ring-primary-500`}
      >
        <button onClick={handleSearch} className="font-medium">
          <IoIosSearch className="text-gray-400 w-4 h-4" />
        </button>
        <input
          ref={inputRef} // Add ref to the input
          type="text"
          placeholder={`Search through ${stats.submittedTools.toLocaleString()} ai tools`}
          className="flex-grow outline-none px-2 h-full py-1"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      <div className="text-xs flex items-center space-x-1.5 absolute mt-2 right-0">
        <Switch size="small" value={devMode} onChange={toggleDevMode} />
        <p>Dev mode</p>
      </div>
    </div>
  );
};

export default SearchBar;
