import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SigninModal from "./SigninModal";
import SettingsButton from "./SettingsButton";
import { TypeAnimation } from "react-type-animation";
import SearchBar from "./SearchBar";

const NavBar = ({ user, setUser, signinModalOpen, setSigninModalOpen, searchable }) => {
  const navigate = useNavigate();

  const toSubmit = () => {
    if (!user) {
      setSigninModalOpen(true);
    } else {
      navigate("/project/0?new=true");
    }
  };

  return (
    <nav className={`w-full flex-shrink-0 md:flex justify-center z-30 py-3 p-3 bg-white`}>
      <SigninModal open={signinModalOpen} setOpen={setSigninModalOpen} />
      <div className="flex max-w-screen-xl w-full h-full justify-between space-x-2">
        <div className="flex space-x-6 w-full items-center">
          <Link to="/" className="flex-shrink-0 font-semibold">
            <TypeAnimation
              sequence={["Raghut", 5000, "raghut", 5000, "rag", 1000]}
              repeat={Infinity}
              speed={20}
              style={{ fontSize: "1.3rem" }}
            />
          </Link>

          {searchable && (
            <div className="md:flex hidden w-full">
              <SearchBar size="sm" />
            </div>
          )}
        </div>

        <div className="flex flex-shrink-0 font-medium space-x-2">
          <button onClick={toSubmit} className="text-sm md:text:base h-9 px-3 flex-shrink-0">
            <p>Submit tool</p>
          </button>

          {user ? (
            <SettingsButton user={user} setUser={setUser} />
          ) : (
            <button
              onClick={() => setSigninModalOpen(true)}
              className="text-sm md:text:base bg-white border border-gray-500 h-9 px-3 flex-shrink-0"
            >
              Sign in
            </button>
          )}
        </div>
      </div>
      {searchable && (
        <div className="md:hidden flex mt-3">
          <SearchBar size="sm" />
        </div>
      )}
    </nav>
  );
};

export default NavBar;
