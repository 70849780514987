import React, { useState } from "react";
import { Card, Badge } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const SearchEngineCard = ({ engine }) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const handleManageDocuments = () => {
    navigate(`/search-engines/${engine.id}/documents`);
  };

  return (
    <Card
      className="hover:shadow-lg transition-shadow overflow-hidden cursor-pointer border border-gray-200"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      styles={{ header: { padding: 0, borderBottom: "none" }, body: { padding: 16 } }}
      title={
        <div className="bg-gray-100 relative h-full w-full overflow-hidden">
          <div className="flex justify-between px-4 py-2 pt-4">
            {/* Status Badge */}
            <Badge
              status={engine.status === "ready" ? "success" : "processing"}
              text={
                <span className=" capitalize text-xs mb-2">
                  {engine.status === "ready" ? "Ready to use" : "Updating files"}
                </span>
              }
            />
          </div>
          <div className="px-4 pb-4 rounded-lg w-full">
            <p className="text-xs mb-2 text-gray-500">Your search engine for</p>

            <div className="flex items-center gap-2 bg-white p-2 rounded border border-gray-200 mb-2">
              <AiOutlineSearch className="text-gray-400" />
              <div className="text-base">{engine.name}</div>
            </div>
          </div>

          {/* Hover Actions */}
          <div
            className={`absolute w-full h-full top-0 bg-slate-900 bg-opacity-80 flex flex-col items-center justify-center gap-1 transition-opacity duration-200 ${
              isHovering ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
          >
            <button
              onClick={handleManageDocuments}
              className="flex items-center bg-white text-blue-600 px-4 py-1 rounded-full hover:opacity-80 transition mt-4"
            >
              <span>Manage documents</span>
            </button>
            <button className="flex items-center text-white px-3 py-1 rounded">
              <span>Edit engine</span>
            </button>
          </div>
        </div>
      }
    >
      <div className="space-y-3">
        <div className="space-y-1">
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Files Added</p>
            <p className="text-xs">{engine.documentsCount}</p>
          </div>
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Times Searched</p>
            <p className="text-xs">{engine.queryCount.toLocaleString()}</p>
          </div>
          <div className="flex space-x-2">
            <p className="text-xs text-gray-500 w-24">Last updated</p>
            <p className="text-xs">{new Date(engine.lastUpdated).toLocaleDateString()}</p>
          </div>
        </div>

        <div className="space-y-3">
          <button className="flex items-center space-x-1 text-blue-600 text-xs w-full hover:underline">
            <p className="truncate">https://andrew.raghut.com/{engine.name}</p>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default SearchEngineCard;
