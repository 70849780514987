import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider } from "antd";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PostHogProvider } from "posthog-js/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = {
  token: {
    fontFamily: '"Space Grotesk", sans-serif',
  },
};

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  peperson_profiles: "always"
};

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <HelmetProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <ConfigProvider theme={theme}>
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </HelmetProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
