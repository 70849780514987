import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import SEOMetaTag from "./utils/SEOMetaTag";
import ScrollToTop from "./utils/scrollToTop";
import NavBar from "./components/NavBar";
import axios from "axios";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import posthog from "posthog-js";
import Dashboard from "./pages/Dashboard";
import DocumentManagement from "./pages/DocumentManagement";

const LazyProjectOld = React.lazy(() => import("./pages/ProjectOld"));
const LazySearch = React.lazy(() => import("./pages/Search"));
const LazyResults = React.lazy(() => import("./pages/Results"));
const LazyAPILanding = React.lazy(() => import("./pages/APILanding"));

function App() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [signinModalOpen, setSigninModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  // Helper function to determine if the user is internal
  const isInternalUser = (user) => {
    if (!user) return false;

    // List of internal email domains
    const internalEmails = ["@raghut.com"];

    // List of specific internal user IDs to exclude
    const internalUserIds = ["t3WmMbMH9xhxWSB6Wdh4pIdO5RC3"]; // Add specific user IDs here

    // Check if the user's email domain is internal or if the user ID matches
    return internalEmails.some((domain) => user.email.endsWith(domain)) || internalUserIds.includes(user.uid);
  };

  const fetchAuthenticatedUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/auth/success`, {
        withCredentials: true,
      });
      if (response.data.success) {
        const fetchedUser = response.data.user;
        setUser(fetchedUser);

        // Exclude internal users from analytics
        if (!isInternalUser(fetchedUser)) {
          posthog.identify(fetchedUser.uid, {
            email: fetchedUser.email,
            name: fetchedUser.displayName,
          });
          posthog.capture("user_logged_in", { email: fetchedUser.email });
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAuthenticatedUser();
  }, [fetchAuthenticatedUser]);

  if (isLoading) {
    return <div className="h-screen w-full flex items-center justify-center text-gray-500">Loading...</div>;
  }

  return (
    <div className="font-space-grotesk">
      <ScrollToTop />
      <Routes>
        {["/", "/project/*", "/api", "/search-engines"].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <NavBar
                user={user}
                setUser={setUser}
                setSigninModalOpen={setSigninModalOpen}
                signinModalOpen={signinModalOpen}
                searchable={false}
              />
            }
          />
        ))}
        <Route
          path="/*"
          element={
            <NavBar
              user={user}
              setUser={setUser}
              setSigninModalOpen={setSigninModalOpen}
              signinModalOpen={signinModalOpen}
              searchable={true}
            />
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/*"
          element={
            <Suspense fallback={<div className="h-screen w-full bg-neutral-50 text-gray-500 p-8">Loading...</div>}>
              <SEOMetaTag
                title="Raghut - AI Tools Discovery Platform"
                description="Discover and explore the latest AI tools with Raghut. Stay up-to-date with tools for creative projects, business automation, and more."
                keywords="AI tools, discover AI tools, explore AI platforms, business AI solutions, creative AI tools"
                url="https://www.raghut.com"
              />
              <LazySearch />
            </Suspense>
          }
        />
        <Route
          path="/project/:project_id"
          element={
            <Suspense fallback={<div className="h-screen w-full bg-neutral-50 text-gray-500 p-8">Loading...</div>}>
              <SEOMetaTag
                title="Explore AI Project - Raghut"
                description="Dive into the details of AI tools and projects. Learn how AI solutions can drive innovation in various fields."
                keywords="AI projects, AI tools, project exploration, AI solutions, technology tools"
                url="https://www.raghut.com/project"
              />
              <LazyProjectOld user={user} />
            </Suspense>
          }
        />
        <Route
          path="/search"
          element={
            <Suspense fallback={<div className="h-screen w-full bg-white"></div>}>
              <SEOMetaTag
                title="Search AI Tools - Raghut"
                description="Search for specific AI tools and discover solutions tailored to your needs. Raghut simplifies your AI tool discovery."
                keywords="search AI tools, find AI tools, discover AI solutions"
                url="https://www.raghut.com/search"
              />
              <LazyResults user={user} setSigninModalOpen={setSigninModalOpen} signinModalOpen={signinModalOpen} />
            </Suspense>
          }
        />
        <Route
          path="/api"
          element={
            <Suspense fallback={<div className="h-screen w-full bg-white"></div>}>
              <SEOMetaTag
                title="Raghut API - AI Tool Search & Discovery Platform"
                description="Access Raghut's powerful RAG-based API to discover, analyze, and integrate AI tools. Get semantic search, rich metadata, and real-time analytics for AI tools and solutions."
                keywords="AI tool API, semantic search API, RAG search engine, AI tool discovery API, AI tool metadata, AI tool analytics API"
                url="https://www.raghut.com/api"
              />
              <LazyAPILanding user={user} />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <>
              <SEOMetaTag
                title="Terms of Use - Raghut"
                description="Read the terms of use for Raghut. Stay informed about the terms and policies that govern your experience."
                keywords="terms of use, Raghut policies"
                url="https://www.raghut.com/terms"
              />
              <Terms />
            </>
          }
        />
        <Route
          path="/privacy"
          element={
            <>
              <SEOMetaTag
                title="Privacy Policy - Raghut"
                description="Learn how Raghut protects your privacy and handles your data securely."
                keywords="privacy policy, data security, Raghut"
                url="https://www.raghut.com/privacy"
              />
              <Privacy />
            </>
          }
        />
        {/* Search engine routes */}
        <Route path="/search-engines">
          {/* List/dashboard of search engines */}
          <Route index element={<Dashboard user={user} />} />

          {/* Document management for specific search engine */}
          <Route
            path=":engineId/documents"
            element={
              <>
                <DocumentManagement />
              </>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
